/** Action to log in the User from the Login Page */
import {createAction, props, union} from '@ngrx/store';
import {MessageModel} from '../models/message.model';
import {LocationModel} from '../../locations/models/location.model';

export const addMessage = createAction(
  '[Messages] addMessage',
  (message: MessageModel, markAsRead: boolean = false) => {
    return { message, markAsRead };
  },
);

export const markMessageAsRead = createAction(
  '[Messages] markMessageAsRead',
  props<MessageModel>(),
);

export const loadMessages = createAction(
  '[Messages] loadMessages',
);

export const loadMessagesByLocationId = createAction(
  '[Messages] loadMessagesByLocationId',
  props<{ locationId: number }>(),
);

export const loadingMessagesForLocation = createAction(
  '[Messages] loadingMessagesForLocation',
  (location: LocationModel = null) => location,
);

export const messagesLoadedForLocation = createAction(
  '[Messages] messagesLoadedForLocation',
  (location: LocationModel = null) => location,
);

export const messagesLoadingForLocationFailed = createAction(
  '[Messages] messagesLoadingForLocationFailed',
  (location: LocationModel = null) => location,
);

export const clearMessages = createAction(
  '[Messages] clearMessages',
);

// Add action to this, so the typing in messages.effects knows the data, otherwise your editor doesnt know the action data in effects
const actions = union({
  loadMessagesByLocationId
});

export type MessageActionsUnion = typeof actions;
