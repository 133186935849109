/** Action to log in the User from the Login Page */
import {createAction, props} from '@ngrx/store';

// Define name to prevent pollution with double names
export type ValidPageNames =
    'HOME'
    | 'ENERGY_BOX'
    | 'ENERGY_CONTROL'
    | 'ENERGY_DAY'
    | 'ENERGY_SPREAD'
    | 'ENERGY_PROFILE';

export const addLoader = createAction(
    '[Loader] add loader',
    props<{ name: ValidPageNames }>(),
);

export const removeLoader = createAction(
    '[Loader] remove loader',
    (name: ValidPageNames, removeAll: boolean = false) => {
        return {name, removeAll};
    },
);
export const addRouteBlockingLoader = createAction(
    '[Loader] addRouteBlockingLoader',
    props<{ name: ValidPageNames }>(),
);

export const removeRouteBlockingLoader = createAction(
    '[Loader] removeRouteBlockingLoader',
    (name: ValidPageNames, removeAll: boolean = false) => {
        return {name, removeAll};
    },
);
