import {createSelector} from '@ngrx/store';
import {LoaderStore} from '../models/loader.model';
import {addLoader, addRouteBlockingLoader, removeLoader, removeRouteBlockingLoader} from '../actions/loader.actions';
import {AppState} from '../../services/app-state';

const initialAuth: LoaderStore = {
  loaders: [],
  routeBlockingLoaders: []
};

export function LoaderReducer(state = initialAuth, action: any = {type: ''}) {
  state = {...state};
  state.loaders = [...state.loaders];
  state.routeBlockingLoaders = [...state.routeBlockingLoaders];

  switch (action.type) {
    case addLoader.type:
      if (action.name) {
        state.loaders.push(action.name);
      }
      return state;

    case addRouteBlockingLoader.type:
      if (action.name) {
        state.routeBlockingLoaders.push(action.name);
      }
      return state;

    case removeLoader.type:
      if (action.removeAll) {
        state.loaders = state.loaders.filter((loader) => action.name === loader);
      } else if (action.name) {
        const firstPos = state.loaders.indexOf(action.name);

        if (firstPos >= 0) {
          state.loaders.splice( firstPos, 1 );
        }
      }
      return state;

    case removeRouteBlockingLoader.type:
      if (action.removeAll) {
        state.routeBlockingLoaders = state.routeBlockingLoaders.filter((loader) => action.name === loader);
      } else if (action.name) {
        const firstPos = state.routeBlockingLoaders.indexOf(action.name);

        if (firstPos >= 0) {
          state.routeBlockingLoaders.splice( firstPos, 1 );
        }
      }
      return state;

    default:
      return state;
  }
}

export const selectLoaders = (state: AppState) => {
  return state.loader.loaders;
};

export const isLoading = createSelector(
  selectLoaders,
  (state: string[]) => state.length > 0
);

export const selectRouteBlockingLoaders = (state: AppState) => {
  return state.loader.routeBlockingLoaders;
};

export const hasRouteBlockingLoaders = createSelector(
    selectRouteBlockingLoaders,
  (state: string[]) => state.length > 0
);
