import {isLoggingIn, login, loginSucceeded, logOut, setAuthToken, setDeviceData} from '../actions/auth.actions';
import {AuthStore} from '../models/auth-store.model';
import {AppState} from '../../services/app-state';
import {createSelector} from '@ngrx/store';
import {Token} from '../models/token.model';
import {DeviceDataModel} from '../models/device-data.model';

const initialAuth: AuthStore = {
  token: null,
  isLoggingIn: false,
  isLoggedIn: false,
  device: null
};

export function AuthReducer(state = initialAuth, action: any = {type: ''}) {
  switch (action.type) {
    case login.type:
      return {...state};

    case isLoggingIn.type:
      return {...state, isLoggingIn: true, isLoggedIn: false};

    case setDeviceData.type:
      const newDeviceData = {...action};
      // the property pushtoken is not required. If it's not in the action, don't update the value
      if (typeof newDeviceData.pushToken === 'undefined' && state.device && !!state.device.pushToken) {
        newDeviceData.pushToken = state.device.pushToken;
      }
      return {...state, device: newDeviceData};

    case setAuthToken.type:
    case loginSucceeded.type:
      return {...state, token: action, isLoggingIn: false, isLoggedIn: true};

    case logOut.type:
      // remember device, that won't change when logout
      return {
        ...initialAuth,
        device: {...state.device}
      };

    default:
      return state;
  }
}

export const selectIsLoggingIn = (state: AppState) => state.auth.isLoggedIn;
export const selectToken = (state: AppState) => state.auth.token;
export const selectDevice = (state: AppState) => state.auth.device;
export const selectIsLoggedIn = (state: AppState) => state.auth.isLoggedIn;
export const selectAccessToken = (state: AppState) => state.auth.token ? state.auth.token.accessToken : null;

export const bearerToken = createSelector(
  selectAccessToken,
  (state: string) => state
);

export const getToken = createSelector(
  selectToken,
  (state: Token) => state
);

export const isLoggedIn = createSelector(
  selectIsLoggedIn,
  (state: boolean) => state
);

export const deviceData = createSelector(
  selectDevice,
  (state: DeviceDataModel) => state
);
