import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthService} from './providers/auth.service';
import {LoginPageModule} from './pages/login/login.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LoginPageModule
  ],
  providers: [
    AuthService,
  ]
})
export class AuthModule { }
