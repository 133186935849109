import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {LoginPageRoutingModule} from './login-routing.module';

import {LoginPage} from './login.page';
import {RouterModule, Routes} from '@angular/router';
import {LOGIN_PAGE} from '../../../../constants';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DictionaryModule} from '../../../dictionary/dictionary.module';
import {DictionaryResolver} from '../../../dictionary/resolvers/dictionary.resolver';

const routes: Routes = [
  {
    path: LOGIN_PAGE,
    component: LoginPage,
    resolve: {
      dictionary: DictionaryResolver
    },
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    IonicModule,
    LoginPageRoutingModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    DictionaryModule
  ],
  declarations: [LoginPage]
})
export class LoginPageModule {}
