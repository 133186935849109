import {SettingsStore} from '../models/settings-store.model';

const initialSettings: SettingsStore = {
};

export function SettingsReducer(state = initialSettings, action: any = {type: ''}) {
  switch (action.type) {

    default:
      return {...state};
  }
}
