import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {IonicModule} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ActionReducer, ActionReducerMap, MetaReducer, StoreModule} from '@ngrx/store';
import {CacheModule} from './modules/cache/cache.module';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AuthModule} from './modules/auth/auth.module';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './modules/auth/effects/auth.effects';
import {LoaderReducer} from './modules/loader/reducers/loader.reducer';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {AppState} from './modules/services/app-state';
import {SettingsReducer} from './modules/settings/reducers/settings.reducer';
import {AuthReducer} from './modules/auth/reducers/auth.reducer';
import {AuthUserReducer} from './modules/auth/reducers/auth-user.reducer';
import {localStorageSync} from 'ngrx-store-localstorage';
import {
  faAddressCard,
  faArrowRight,
  faBox,
  faBuilding,
  faCalendarAlt,
  faChartBar,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCog,
  faEllipsisV,
  faEnvelope,
  faEuroSign,
  faHome,
  faInbox,
  faPaperPlane,
  faPhone,
  faSync,
  faTable,
  faThLarge,
  faTrash,
  faTv,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import {CanDeactivateGuard} from './modules/router/services/can-deactivate.guard';
import {DictionaryReducer} from './modules/dictionary/reducers/dictionary.reducer';
import {IntroReducer} from './modules/intro/reducers/intro.reducer';
import {enovaElement, liveChat, login, logout, myAccount, settings} from './modules/services/custom-icons';
import {MessagesReducer} from './modules/messages/reducers/messages.reducer';
import {MessagesEffects} from './modules/messages/effects/messages.effects';
import {DictionaryModule} from './modules/dictionary/dictionary.module';

const reducers: ActionReducerMap<AppState> = {
  settings: SettingsReducer,
  auth: AuthReducer,
  authUser: AuthUserReducer,
  loader: LoaderReducer,
  dictionary: DictionaryReducer,
  intro: IntroReducer,
  messages: MessagesReducer
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: ['auth', 'settings', 'authUser', 'dictionary', 'messages'], rehydrate: true})(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    NgSelectModule,
    FormsModule,
    DictionaryModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    CacheModule,
    IonicModule.forRoot(),
    StoreModule.forRoot(reducers, {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
        },
      },
    ),
    FontAwesomeModule,
    AppRoutingModule,
    AuthModule,
    EffectsModule.forRoot([AuthEffects, MessagesEffects]),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    })],
  providers: [
    StatusBar,
    SplashScreen,
    InAppBrowser,
    CanDeactivateGuard,
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faCheckCircle);
    library.addIcons(faUserPlus);
    library.addIcons(faHome);
    library.addIcons(faPhone);
    library.addIcons(faCog);
    library.addIcons(faInbox);
    library.addIcons(faBuilding);
    library.addIcons(faEnvelope);
    library.addIcons(faBox);
    library.addIcons(faChartBar);
    library.addIcons(faEllipsisV);
    library.addIcons(faThLarge);
    library.addIcons(faTable);
    library.addIcons(faAddressCard);
    library.addIcons(faChartBar);
    library.addIcons(faChevronLeft);
    library.addIcons(faChevronRight);
    library.addIcons(faArrowRight);
    library.addIcons(faClock);
    library.addIcons(liveChat);
    library.addIcons(enovaElement);
    library.addIcons(faCalendarAlt);
    library.addIcons(faEuroSign);
    library.addIcons(myAccount);
    library.addIcons(settings);
    library.addIcons(logout);
    library.addIcons(login);
    library.addIcons(faPaperPlane);
    library.addIcons(faTv);
    library.addIcons(faSync);
    library.addIcons(faTrash);
  }
}
