import { Injectable } from '@angular/core';
import {ToastController} from '@ionic/angular';
import {WaTranslatePipe} from '../../dictionary/pipes/wa-translate.pipe';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private toast: Promise<void>;

  constructor(private toastController: ToastController, private waTranslate: WaTranslatePipe) {}

  showDefaultError() {
    console.warn('showing default error');
    if (this.toast) {
      return;
    }
    this.toastController.create({
      header: this.waTranslate.transform('GETTING_DATA_FAILED_TITLE'),
      message: this.waTranslate.transform('GETTING_DATA_FAILED_DESCRIPTION'),
      color: 'danger',
      buttons: [
        {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    }).then((toast) => {
      this.toast = toast.present();
      toast.onDidDismiss().then(() => this.toast = null);
    });
  }
}
