import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Observable, of, throwError} from 'rxjs';
import {bearerToken, selectDevice, selectToken} from '../../auth/reducers/auth.reducer';
import {HttpClient as Http, HttpHeaders} from '@angular/common/http';
import {catchError, map, mergeMap, shareReplay, switchMap, take, tap, timeout} from 'rxjs/operators';
import {AppState} from '../../services/app-state';
import {activeLocation} from '../../auth/reducers/auth-user.reducer';
import {LocationModel} from '../../locations/models/location.model';
import {differenceInSeconds, isBefore} from 'date-fns';
import {environment} from '../../../../environments/environment';
import {logOut, setAuthToken} from '../../auth/actions/auth.actions';
import {Token} from '../../auth/models/token.model';
import {TokenRes} from '../../auth/models/token-res.model';
import {REFRESHING_TOKEN_FAILED} from '../../errors/providers/error.constants';

@Injectable({
  providedIn: 'root'
})
export class HttpClient {
  public baseApi = '';
  private refreshingToken$: Observable<Token>;

  constructor(private store: Store<AppState>, private http: Http) {
  }

  private serialize(obj: any, prefix: any = null): string {
    const str: any = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p;
        const v = obj[p];
        str.push(typeof v === 'object' ?
          this.serialize(v, k) :
          // encodeURIComponent(k) + '=' + encodeURIComponent(v));
          k + '=' + encodeURIComponent(v));
      }
    }
    return str.join('&');
  }

  private createHeader(token: string, currentLocation: LocationModel = null) {
    const header: any = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json'
    };

    if (token && token.length > 0) {
      header.Authorization = 'Bearer ' + token;
      header.yeardefault = new Date().getFullYear().toString();
      if (currentLocation && currentLocation.id) {
        header.location = currentLocation.id.toString();
      }
    }

    return new HttpHeaders(header);
  }

  private convertedIdsToInts(obj: any = {}) {
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        if (p.substr(-3) === '_id' && !isNaN(obj[p])) {
          obj[p] = +obj[p];
        }
      }
    }
    return obj;
  }

  public get(url: string, params: any = {}, timeoutTime: number = null): Observable<any> {
    return this.refreshToken().pipe(
      mergeMap((token) => this.defaultHeader(token)),
      mergeMap((headers) => {
        return this.http.get(url, {params, headers});
      }),
      timeout(timeoutTime !== null ? timeoutTime : environment.defaultTimeout),
      catchError((e) => this.handleDefaultError(e)),
      take(1)
    );
  }

  public post(url: string, data: any = {}, params: any = {}, timeoutTime: number = null): Observable<any> {
    return this.refreshToken().pipe(
      mergeMap((token) => this.defaultHeader(token)),
      mergeMap((headers) => {
        return this.http.post(url, this.serialize(data), {
          headers,
          params
        });
      }),
      timeout(timeoutTime !== null ? timeoutTime : environment.defaultTimeout),
      catchError((e) => this.handleDefaultError(e)),
      take(1)
    );
  }

  public put(url: string, data: any = {}, params: any = {}, timeoutTime: number = null): Observable<any> {
    data = this.convertedIdsToInts(data);
    return this.refreshToken().pipe(
      mergeMap((token) => this.defaultHeader(token)),
      mergeMap((headers) => {
        return this.http.put(url, data, {headers});
      }),
      timeout(timeoutTime !== null ? timeoutTime : environment.defaultTimeout),
      catchError((e) => this.handleDefaultError(e)),
      take(1)
    );
  }

  public delete(url: string, params: any = {}, timeoutTime: number = null): Observable<any> {
    return this.refreshToken().pipe(
      mergeMap((token) => this.defaultHeader(token)),
      mergeMap((headers) => {
        return this.http.delete(url, {
          headers
        });
      }),
      timeout(timeoutTime !== null ? timeoutTime : environment.defaultTimeout),
      catchError((e) => this.handleDefaultError(e)),
    );
  }

  // Request a new token with the refresh token, if the normal token expires. Can be force by force = true
  refreshToken(force = false): Observable<Token> {
    return combineLatest([
      this.store.select(selectDevice).pipe(take(1)),
      this.store.select(selectToken).pipe(take(1)),
    ]).pipe(
      // Share result if multiple calls are made
      switchMap(([device, token]) => {
        if (token && (force || isBefore(new Date(token.expires), new Date()))) {
          // Share result if multiple calls are made
          if (!this.refreshingToken$) {
            this.refreshingToken$ = this.http.post<TokenRes>(environment.apiHost + '/refreshtoken', this.serialize({
                deviceId: device.deviceId,
                username: token.username,
                refreshToken: token.refreshToken,
              }),
              {
                headers: this.createHeader(token.accessToken)
              }).pipe(
              mergeMap((res) => {
                if (!res) {
                  this.refreshingToken$ = null;
                  return throwError({...res, key: REFRESHING_TOKEN_FAILED});
                }
                return of(res);
              }),
              take(1),
              map((newToken) => ({
                accessToken: newToken.access_token,
                expires: newToken.expires,
                expiresIn: newToken.expires_in,
                refreshToken: newToken.refresh_token,
                tokenType: newToken.token_type,
                username: newToken.username,
              })),
              tap((newToken) => {
                this.refreshingToken$ = null;
                this.store.dispatch(setAuthToken(newToken));
              }),
              timeout(environment.defaultTimeout),
              catchError((err) => {
                this.refreshingToken$ = null;
                return throwError({...err, key: REFRESHING_TOKEN_FAILED});
              }),
              shareReplay(1),
            );
          }

          return this.refreshingToken$;
        }
        return of(token);
      }),
      take(1)
    );
  }

  private handleDefaultError(e: any) {
    if (e && e.status) {
      console.log(e);
      console.log(e.status);
      if (e.status === 401) {
        this.store.dispatch(logOut());
      }
    }
    console.log(e);
    return throwError(e);
  }

  private defaultHeader(newToken) {
    const currToken = newToken ? newToken.accessToken : null;
    return combineLatest([of(currToken), this.store.select(activeLocation)]).pipe(
      take(1),
      map(([token, currentLocation]) => {
        return this.createHeader(token, currentLocation);
      }));
  }
}
