import {setIntroSlides} from '../actions/intro.actions';
import {IntroStoreModel} from '../models/intro-store.model';
import {createSelector} from '@ngrx/store';
import {LocationModel} from '../../locations/models/location.model';
import {selectActiveLocation} from '../../auth/reducers/auth-user.reducer';
import {AppState} from '../../services/app-state';
import {IntroSlideModel} from '../models/intro-slide.model';

const initialIntro: IntroStoreModel = {
  slides: []
};

export function IntroReducer(state = initialIntro, action: any = {type: ''}) {
  switch (action.type) {
    case setIntroSlides.type:
      return {
        ...state,
        slides: action.slides
      };
    default:
      return {...state};
  }
}

export const selectIntroSlides = (state: AppState): IntroSlideModel[] => state.intro.slides;
