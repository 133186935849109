import {OnDestroy, OnInit, Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectDictionary} from '../reducers/dictionary.reducer';
import {Observable, Subscription} from 'rxjs';
import {AppState} from '../../services/app-state';
import {map, mergeMap} from 'rxjs/operators';

@Pipe({
  name: 'waTranslate'
})
export class WaTranslatePipe implements PipeTransform, OnDestroy {
  private dictionary: any;
  subscription: Subscription = new Subscription();

  constructor(private store: Store<AppState>) {
    this.subscription.add(this.store.select(selectDictionary).subscribe((dictionary) => {
      this.dictionary = {...dictionary};
    }));
  }

  transform(value: string, ...args: unknown[]): string {
    return typeof this.dictionary[value] !== 'undefined' ? this.dictionary[value] : `'${value}'`;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription = new Subscription();
  }

}
