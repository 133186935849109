/** Action to log in the User from the Login Page */
import {createAction, props} from '@ngrx/store';
import {AuthCredentials} from '../models/auth-credentials.model';
import {Auth} from '../models/auth.model';
import {Token} from '../models/token.model';
import {DeviceDataModel} from '../models/device-data.model';

export const login = createAction(
  '[Login Page] Login',
  props<AuthCredentials>(),
);

export const isLoggingIn = createAction(
  '[Login Page] Is logging in',
  () => ({ isLoggingIn: true }),
);

export const setDeviceData = createAction(
  '[Login Page] setDeviceData',
  props<DeviceDataModel>()
);

export const loginSucceeded = createAction(
  '[Login Page] Login succeeded',
  props<Token>()
);

// Same as loginSucceeded, but without side effects
export const setAuthToken = createAction(
  '[Login Page] setAuthToken',
  props<Token>()
);

export const logOut = createAction(
  '[Login Page] Logout'
);

