export const liveChat: any = {
  prefix: 'fas',
  iconName: 'liveChat',
  icon: [
    100, 100,
    [],
    null,
    'M126.018,41.763V56.505a11.0521,11.0521,0,0,1-11.052,11.052h-7.758a47.7939,47.7939,0,0,1-32.952,28.2V100.8A11.052,11.052,0,1,1,63.283,89.667a40.413,40.413,0,0,0,38.046-26.978v-.042a40.5233,40.5233,0,0,0,1.816-20.178,31.8657,31.8657,0,0,0-1.726-6.721l-.032-.085A40.547,40.547,0,0,0,24.952,62.752l1.579,4.826H11.421A11.052,11.052,0,0,1,.369,56.526V41.79A11.0531,11.0531,0,0,1,11.421,30.742h7.574a47.894,47.894,0,0,1,88.266,0h7.71A11.053,11.053,0,0,1,126.018,41.763ZM37.136,69.626A33.126,33.126,0,1,1,63.193,82.289H30.063V74.92A7.3189,7.3189,0,0,0,37.136,69.626Zm37.099-16.81H81.6V45.447H74.235ZM59.499,45.447l.001,7.369h7.367V45.447ZM44.762,52.816h7.369V45.447H44.762Z'
  ]
};

export const enovaElement: any = {
  prefix: 'fas',
  iconName: 'enovaElement',
  icon: [
    114, 125,
    [],
    null,
    'M67.1345,59.8657,55.1082,21.141l27.3-8.5388,17.3179,22.97Zm31.87-8.8995L65.8116,74.4176,99.0043,97.8689,115.48,74.4176ZM18.4279,78.0255l0,28.5024,27.0592,9.7414L58.9567,78.0255ZM28.7705.5759l-26.939,9.621,0,28.6228H42.36ZM51.5,116.75l27.42,8.5387,17.1977-22.85L63.7671,78.0255Z'
  ]
};

export const myAccount: any = {
  prefix: 'fas',
  iconName: 'myAccount',
  icon: [
    110, 118,
    [],
    null,
    'M109.9289,102.4582v7.4707a7.8522,7.8522,0,0,1-7.8521,7.8521H7.8522A7.8521,7.8521,0,0,1,0,109.9289v-7.4707a23.6068,23.6068,0,0,1,18.929-23.1019l17.1736-3.438A3.9259,3.9259,0,0,0,39.26,72.0708v-3.926a47.65,47.65,0,0,1-3.3034-5.85,34.6047,34.6047,0,0,0-2.7034-4.7674,25.2831,25.2831,0,0,1-7.9474-12.5689,12.49,12.49,0,0,1,.9871-9.2093l.1122-2.7539a46.1308,46.1308,0,0,1,1.0768-14.212A30.8473,30.8473,0,0,1,54.813,0,27.6285,27.6285,0,0,1,68.1054,3.5055,12.6392,12.6392,0,0,0,70.77,4.128a12.1761,12.1761,0,0,1,7.2912,3.0567,17.3194,17.3194,0,0,1,4.0774,12.6474A69.3416,69.3416,0,0,1,83.473,35.7663a11.7778,11.7778,0,0,1,1.1217,9.2206,25.3729,25.3729,0,0,1-7.9642,12.5689,34.0369,34.0369,0,0,0-2.67,4.7449,45.3384,45.3384,0,0,1-3.292,5.8556v3.926A3.9261,3.9261,0,0,0,73.815,75.93l17.1735,3.4381A23.6068,23.6068,0,0,1,109.9289,102.4582Z'
  ]
};

export const settings: any = {
  prefix: 'fas',
  iconName: 'settings',
  icon: [
    111, 116,
    [],
    null,
    'M110.56708,29.125A16.8214,16.8214,0,0,0,99.161,17.71889V4.8376a4.8376,4.8376,0,0,0-9.67511,0V17.71889a16.8214,16.8214,0,0,0,0,32.28241v61.25128a4.8376,4.8376,0,0,0,9.67511,0V50.0013A16.82131,16.82131,0,0,0,110.56708,29.125ZM101.577,33.86293a7.25361,7.25361,0,1,1-7.24793-7.25934h.00012a7.26482,7.26482,0,0,1,7.25348,7.25354Zm-79.81122-16.144V4.8376a4.83761,4.83761,0,0,0-9.67511,0V17.71889a16.8214,16.8214,0,0,0,0,32.28241v61.25128a4.83761,4.83761,0,0,0,9.67511,0V50.0013a16.82142,16.82142,0,0,0,0-32.28241Zm2.416,16.144a7.2536,7.2536,0,1,1-7.25372-7.25354h.00012a7.2648,7.2648,0,0,1,7.25916,7.24793Zm36.27875,32.226V4.8376a4.83757,4.83757,0,1,0-9.675-.04358q-.00018.0218,0,.04358V66.08888a16.82133,16.82133,0,0,0,0,32.28235v12.88135a4.83758,4.83758,0,1,0,9.675.04449q.00018-.02225,0-.04449V98.37123a16.82133,16.82133,0,0,0,0-32.28235ZM55.62872,89.47511a7.25351,7.25351,0,1,1,7.25348-7.25354A7.25351,7.25351,0,0,1,55.62872,89.47511Z'
  ]
};

export const logout: any = {
  prefix: 'fas',
  iconName: 'logout',
  icon: [
    110, 110,
    [],
    null,
    'M59.70551,105.63557a4.59364,4.59364,0,0,1-4.59363,4.59363H13.778A13.79408,13.79408,0,0,1,0,96.45119V13.778A13.79407,13.79407,0,0,1,13.778,0H55.11188q.02133-.0001.04266,0a4.59358,4.59358,0,1,1-.04266,9.18707H13.778A4.59635,4.59635,0,0,0,9.18707,13.778V96.45119A4.59626,4.59626,0,0,0,13.778,101.042H55.11188A4.59362,4.59362,0,0,1,59.70551,105.63557Zm49.56842-53.73138-.05-.05.00543-.0108L81.30243,24.28749a4.59391,4.59391,0,0,0-6.45093,6.5423L94.80627,50.5265H41.33392a4.59359,4.59359,0,1,0-.04284,9.18708q.02142.00009.04284,0h53.467L74.84631,79.41017a4.59389,4.59389,0,1,0,6.45087,6.5423l27.9267-27.556A4.591,4.591,0,0,0,109.27393,51.90419Z'
  ]
};

export const login: any = {
  prefix: 'fas',
  iconName: 'login',
  icon: [
    110, 110,
    [],
    null,
    'M59.70551,105.63557a4.59364,4.59364,0,0,1-4.59363,4.59363H13.778A13.79408,13.79408,0,0,1,0,96.45119V13.778A13.79407,13.79407,0,0,1,13.778,0H55.11188q.02133-.0001.04266,0a4.59358,4.59358,0,1,1-.04266,9.18707H13.778A4.59635,4.59635,0,0,0,9.18707,13.778V96.45119A4.59626,4.59626,0,0,0,13.778,101.042H55.11188A4.59362,4.59362,0,0,1,59.70551,105.63557Zm-21.667-47.29978.05005.05-.00543.01081L66.01,85.95249A4.59391,4.59391,0,1,0,72.461,79.4102L52.50619,59.71348l53.47235,0a4.59358,4.59358,0,1,0,.04284-9.18707q-.02142-.00009-.04284,0l-53.467,0L72.46615,30.82982a4.59389,4.59389,0,1,0-6.45086-6.5423l-27.9267,27.556A4.591,4.591,0,0,0,38.03854,58.33579Z'
  ]
};
