import {ChangeDetectorRef, Component, ViewRef} from '@angular/core';
import {Location} from '@angular/common';

import {NavController, Platform, ToastController} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppState} from './modules/services/app-state';
import {Store} from '@ngrx/store';
import {take} from 'rxjs/operators';
import {createDashboardUrl} from './modules/router/services/router.helper';
import {MESSAGES_PAGE} from './constants';

import {Capacitor, Plugins, PushNotification, PushNotificationActionPerformed, PushNotificationToken} from '@capacitor/core';
import {loadMessagesByLocationId} from './modules/messages/actions/messages.actions';
import {setAuthUserActiveLocationId} from './modules/auth/actions/auth-user.actions';
import {AuthService} from './modules/auth/providers/auth.service';
import {DeviceDataModel} from './modules/auth/models/device-data.model';
import {loginSucceeded, logOut, setDeviceData} from './modules/auth/actions/auth.actions';
import {Subscription} from 'rxjs';
import {setDictionary} from './modules/dictionary/actions/dictionary.actions';
import {DictionaryService} from './modules/dictionary/providers/dictionary.service';
import {REFRESHING_TOKEN_FAILED} from './modules/errors/providers/error.constants';
import {VersionService} from './modules/version/providers/version.service';
import {selectDictionary} from './modules/dictionary/reducers/dictionary.reducer';

const {Device} = Plugins;

const {PushNotifications} = Plugins;
const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  subscription: Subscription = new Subscription();

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private authService: AuthService,
    private navCtrl: NavController,
    private statusBar: StatusBar,
    private cdr: ChangeDetectorRef,
    private toastController: ToastController,
    private versionService: VersionService,
    private navController: NavController,
    private dictionaryService: DictionaryService,
    private location: Location,
    private store: Store<AppState>
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();

      Device.getInfo().then((device) => {
        const data: DeviceDataModel = {
          appVersion: device.appVersion,
          model: device.model,
          platform: device.operatingSystem,
          version: device.osVersion,
          manufacturer: device.manufacturer,
          deviceId: device.uuid,
        };
        this.store.dispatch(setDeviceData(data));

        if (device.platform === 'web') {
          this.authService.saveDeviceData(data).subscribe((res) => console.log(res));
        }

        // Always refresh the token at start, so the token will live longer
        this.subscription.add(this.authService.getRefreshToken(true).pipe(take(1)).subscribe((res) => {
            console.log('Token refresh response: ', res);
          },
          (res) => {
            console.log('Refreshing token failed', res);
            if (res && res.key && res.key === REFRESHING_TOKEN_FAILED) {
              this.store.dispatch(logOut());
            }
          }, () => {
            console.log('Done trying to refresh token, continue app');
            this.versionService.validateVersion().subscribe(
              (res) => {
                console.log(res);
              },
              (res) => {
                console.log(res);
              },
              () => this.splashScreen.hide());
          }));
      });

      if (isPushNotificationsAvailable) {
        this.initPushNotifications();
      }
    });
  }

  private initPushNotifications() {

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then(result => {
      console.log('PushNotifications.requestPermission()', result);
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
        console.log('registration', token);
        this.saveDeviceInfo(token.value);
        console.log('Push registration success, token: ', token.value);
      },
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.error('Error on registration: ', error);
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotification) => {
        if (notification.data.body) {
          try {
            const data = JSON.parse(notification.data.body);
            console.log(data);
            if (data.type === 'newMessage') {
              this.store.dispatch(loadMessagesByLocationId({locationId: data.locationId}));

              this.toastController.create({
                header: notification.title,
                position: 'top',
                buttons: [
                  {
                    side: 'start',
                    icon: 'mail',
                    text: 'Bekijk bericht',
                    handler: () => {
                      this.navCtrl.navigateRoot(createDashboardUrl(MESSAGES_PAGE));
                      if (!!data.locationId) {
                        this.store.dispatch(setAuthUserActiveLocationId({locationId: data.locationId}));
                      }
                    }
                  }, {
                    text: 'Done',
                    role: 'cancel',
                    handler: () => {
                      console.log('Cancel clicked');
                    }
                  }
                ]
              }).then((toast) => {
                toast.present();
              });
            }
          } catch (exception) {
            console.error(exception);
          }
        }
        console.log('Push received: ', notification);
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        if (notification.notification.data.body) {
          try {
            const data = JSON.parse(notification.notification.data.body);
            console.log(data);
            if (data.type === 'newMessage') {
              this.navCtrl.navigateRoot(createDashboardUrl(MESSAGES_PAGE));
              if (!!data.locationId) {
                // ue timeout hack, otherwise template change wont trigger
                setTimeout(() => {
                  this.store.dispatch(setAuthUserActiveLocationId({locationId: data.locationId}));
                }, 1);
              }
            }
          } catch (exception) {

          }
        }
        console.log('Push action performed: ', notification);
      },
    );
  }

  private saveDeviceInfo(token) {
    Device.getInfo().then((device) => {
      if (device && token) {
        const data: DeviceDataModel = {
          appVersion: device.appVersion,
          model: device.model,
          platform: device.operatingSystem,
          version: device.osVersion,
          manufacturer: device.manufacturer,
          deviceId: device.uuid,
          pushToken: token
        };

        this.store.dispatch(setDeviceData(data));

        this.authService.saveDeviceData(data).subscribe((res) => console.log(res));
      }
    });
  }

  detectChanges() {
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

}
