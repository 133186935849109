import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WaTranslatePipe} from './pipes/wa-translate.pipe';



@NgModule({
  declarations: [
    WaTranslatePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    WaTranslatePipe
  ],
})
export class DictionaryModule {
  static forRoot() {
    return {
      ngModule: DictionaryModule,
      providers: [WaTranslatePipe],
    };
  }
}
