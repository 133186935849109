import {MessageModel} from '../models/message.model';
import {MessageStoreModel} from '../models/message-store.model';
import {
  addMessage,
  clearMessages,
  loadingMessagesForLocation,
  markMessageAsRead,
  messagesLoadedForLocation, messagesLoadingForLocationFailed
} from '../actions/messages.actions';
import {AppState} from '../../services/app-state';
import {createSelector} from '@ngrx/store';
import {selectActiveLocation} from '../../auth/reducers/auth-user.reducer';
import {LocationModel} from '../../locations/models/location.model';

const baseLocation = {
  locationId: null,
  dateFrom: null,
  isLoadingMessages: false
};

const initialMessage: MessageStoreModel = {
  messages: [],
  locations: [{...baseLocation}],
  readIds: []
};


export function MessagesReducer(state = initialMessage, action: any = {type: ''}) {
  switch (action.type) {
    case addMessage.type:
      const messages = state.messages.filter((message) => message.id !== action.message.id).concat(action.message).sort(
        (a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime());

      let readIds = [...state.readIds];
      if (action.markAsRead) {
        readIds = state.readIds.filter((id) => id !== action.message.id);
        readIds.push(action.message.id);
      }

      return {
        ...state,
        messages,
        locations: state.locations.map((location) => {
          const locationA = location.locationId;
          const locationB = !!action.message.locationId ? action.message.locationId : null;
          location = {...location};
          if (locationA === locationB &&
            (!location.dateFrom || (new Date(action.message.updatedAt).getTime() - new Date(location.dateFrom).getTime()) > 0)) {
            location.dateFrom = action.message.updatedAt;
          }
          return location;
        }),
        readIds
      };

    case markMessageAsRead.type:
      const copyMarkMessages = {...state};
      copyMarkMessages.readIds = [...copyMarkMessages.readIds.filter((id) => id !== action.id)];
      copyMarkMessages.readIds.push(action.id);
      return copyMarkMessages;


    case loadingMessagesForLocation.type:
      const locationId = !!action.id ? action.id : null;

      const foundLocation = state.locations.find((location) => location.locationId === locationId);
      const locations = state.locations.filter((location) => location.locationId !== locationId);

      locations.push({
        ...baseLocation,
        ...foundLocation,
        locationId,
        isLoadingMessages: true
      });
      return {
        ...state,
        locations
      };

    case messagesLoadingForLocationFailed.type:
    case messagesLoadedForLocation.type:
      const locationId2 = !!action.id ? action.id : null;
      const updatedLocations = state.locations.map((location) => {
        location = {...location};
        if (location.locationId === locationId2) {
          location.isLoadingMessages = false;
        }
        return location;
      });

      return {
        ...state,
        locations: updatedLocations
      };

    case clearMessages.type:
      return {
        ...initialMessage
      };

    default:
      return state;
  }
}

export const selectMessages = (state: AppState) => state.messages.messages;
export const selectMessageLocations = (state: AppState) => state.messages.locations;
export const selectIsLoadingMessages = (state: AppState) => state.messages.locations.some((location) => location.isLoadingMessages);
export const selectUnreadMessages = (state: AppState) => {
  return state.messages.messages.filter((message) => state.messages.readIds.indexOf(message.id) === -1);
};

export const activeMessages = createSelector(
  selectMessages,
  selectActiveLocation,
  (messages: MessageModel[], activeLocation: LocationModel) => {
    return messages.filter((message) => {
      if (activeLocation && activeLocation && +message.locationId === +activeLocation.id) {
        return true;
      }
      if (typeof message.locationId === 'undefined' || !message.locationId) {
        return true;
      }
      return false;
    });
  }
);

export const unreadActiveMessages = createSelector(
  selectUnreadMessages,
  selectActiveLocation,
  (messages: MessageModel[], activeLocation) => {
    return messages.filter((message) => {
      if (activeLocation && activeLocation && +message.locationId === +activeLocation.id) {
        return true;
      }
      if (typeof message.locationId === 'undefined' || !message.locationId) {
        return true;
      }
      return false;
    });
  }
);

export const unreadMessages = createSelector(
  selectUnreadMessages,
  (messages: MessageModel[]) => {
    return messages;
  }
);

export const isLoadingMessages = createSelector(
  selectIsLoadingMessages,
  (isLoading) => {
    return isLoading;
  }
);
