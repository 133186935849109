import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from '../../../services/app-state';
import {isLoggingIn, loginSucceeded, logOut} from '../../actions/auth.actions';
import {AuthCredentials} from '../../models/auth-credentials.model';
import {AlertController, LoadingController, NavController} from '@ionic/angular';
import {AuthService} from '../../providers/auth.service';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {clearAuthUser} from '../../actions/auth-user.actions';
import {createDashboardUrl} from '../../../router/services/router.helper';
import {HOME_PAGE} from '../../../../constants';
import {deviceData} from '../../reducers/auth.reducer';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  private loader: HTMLIonLoadingElement;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private authService: AuthService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private cdr: ChangeDetectorRef,
    private loadingController: LoadingController,
    private store: Store<AppState>) {

  }

  authForm: FormGroup;

  ngOnInit(): void {
    this.store.dispatch(clearAuthUser());
    this.authForm = this.fb.group({
      username: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(2)])]
    });
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Inloggen mislukt',
      message: 'De gebruikersnaam of het wachtwoord is onjuist',
      buttons: ['OK']
    });

    await alert.present();
  }

  login(payload: AuthCredentials) {
    this.store.select(deviceData).pipe(take(1)).subscribe((device) => {
      console.log(device);
      payload.deviceId = device && device.deviceId ? device.deviceId : 'brower';
      this.loadingController.create({
        message: 'Een moment a.u.b.',
      }).then((loader) => {
        loader.present();
        this.store.dispatch(isLoggingIn());
        console.log('loggin in');
        this.authService.login(payload).pipe(take(1)).subscribe(
          (res) => {
            // Arvin: redirecting in auth.effects
            this.store.dispatch(loginSucceeded(res));
            loader.dismiss();
          }, (e) => {
            this.store.dispatch(logOut());
            console.log('login failed', e);
            loader.dismiss();
            this.presentAlert();
          });
      });
    });
  }

  loginAsGuest() {
    this.navCtrl.navigateRoot(createDashboardUrl(HOME_PAGE));
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    if (this.loader) {
      this.loader.dismiss();
      this.loader = null;
    }
  }

  private unsubscribe() {
    this.subscription.unsubscribe();
    this.subscription = new Subscription();
  }
}
