import {setDictionary} from '../actions/dictionary.actions';
import {DictionaryStoreModel} from '../models/dictionary-store.model';
import {AppState} from '../../services/app-state';
import {IntroSlideModel} from '../../intro/models/intro-slide.model';

const initialDictionary: DictionaryStoreModel = {
  dictionary: {}
};

export function DictionaryReducer(state = initialDictionary, action: any = {type: ''}) {
  switch (action.type) {
    case setDictionary.type:
      return {
        ...state,
        dictionary: action.dictionary
      };
    default:
      return {...state};
  }
}

export const selectDictionary = (state: AppState): any => state.dictionary.dictionary;
