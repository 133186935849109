import {Injectable} from '@angular/core';
import {AuthCredentials} from '../models/auth-credentials.model';
import {Observable, of, throwError} from 'rxjs';
import {AuthUser} from '../models/auth-user.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../services/app-state';
import {mergeMap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '../../http/helpers/http-client.service';
import {DeviceDataModel} from '../models/device-data.model';
import {Token} from '../models/token.model';

@Injectable()
export class AuthService {

  constructor(
    private http: HttpClient, private store: Store<AppState>) {
  }


  public login(authCredentials: AuthCredentials): Observable<Token> {
    return this.http.post(environment.apiHost + '/token', {
      ...authCredentials
    }).pipe(mergeMap((res) => {
      if (!res || !res.access_token) {
        return throwError(new Error('Incorrect auth'));
      }
      return of({
        accessToken: res.access_token,
        expires: res.expires,
        expiresIn: res.expires_in,
        refreshToken: res.refresh_token,
        tokenType: res.token_type,
        username: res.username,
      });
    }));
  }

  public getAuthUser(): Observable<AuthUser> {
    return this.http.get(environment.apiHost + '/claims');
  }

  public getRefreshToken(force = false): Observable<Token> {
    return this.http.refreshToken(force);
  }

  public saveDeviceData(data: DeviceDataModel): Observable<AuthUser> {
    return this.http.post(environment.apiHost + '/device', data);
  }
}
