import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {DASHBOARD, INTRO} from './constants';
import {DictionaryResolver} from './modules/dictionary/resolvers/dictionary.resolver';

const routes: Routes = [
  {
    path: DASHBOARD,
    resolve: {
      dictionary: DictionaryResolver
    },
    loadChildren: () => import('./modules/dashboard/dashboard.module')
      .then( m => m.DashboardModule)
  },
  {
    path: INTRO,
    resolve: {
      dictionary: DictionaryResolver
    },
    loadChildren: () => import('./modules/intro/intro.module')
      .then( m => m.IntroModule)
  },
  {
    path: '',
    resolve: {
      dictionary: DictionaryResolver
    },
    loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
