/* main categories */
export const AUTH = 'auth';
export const DASHBOARD = 'dashboard';
export const INTRO = 'intro';

/* product keys */
export const ENERGY_BOX = 'energybox';
export const ENERGY_CONTROL = 'energycontrol';
export const ENERGY_DAY = 'energyday';
export const ENERGY_PROFILE = 'energyprofile';
export const ENERGY_SPREAD = 'energytrade';

/* pages */
export const LOGIN_PAGE = 'login';
export const HOME_PAGE = 'home';
export const SETTINGS_PAGE = 'settings';
export const ENERGY_BOX_PAGE = 'energy-box';
export const ENERGY_CONTROL_PAGE = 'energy-control';
export const ENERGY_DAY_PAGE = 'energy-day';
export const ENERGY_PROFILE_PAGE = 'energy-profile';
export const ENERGY_SPREAD_PAGE = 'energy-spread';
export const MESSAGES_PAGE = 'messages';
export const PRODUCTS_PAGE = 'products';
export const LOCATIONS_PAGE = 'locations';
export const CONTACT_PAGE = 'contact';
export const ABOUT_US_PAGE = 'about-us';
export const MARKET_DATA_PAGE = 'market-data';
