import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {MessageIndexModel} from '../models/message-index.model';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '../../http/helpers/http-client.service';
import {MessageCategoryModel} from '../models/message-category.model';
import {MessageModel} from '../models/message.model';
import {mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) {
  }

  public index(locationId: number = null, dateFrom: string = null): Observable<MessageModel[]> {
    const url = environment.apiHost + `/messages`;
    return this.http.post(url, {
      locationId,
      dateFrom
    });
  }

  public getMessageCategories(): Observable<MessageCategoryModel[]> {
    const url = environment.apiHost + `/messagecategories`;
    return this.http.get(url);
  }
}
