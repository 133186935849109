import * as localforage from 'localforage';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';

@Injectable()
export class LocalStorageService {

  constructor() {
    // localforage.setDriver(localforage.WEBSQL);
  }

  public setItem<T>(key: string, value: T): Observable<any> {
    return from(localforage.setItem(key, value));
  }

  public getItem(key: string): Observable<any> {
    return from(localforage.getItem(key));
  }

  public removeItem(key: string): Observable<any> {
    return from(localforage.removeItem(key));
  }
}
