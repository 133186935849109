import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {AppState} from '../../services/app-state';
import {Store} from '@ngrx/store';
import {ModalController, NavController} from '@ionic/angular';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '../../http/helpers/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  constructor(
    private http: HttpClient
  ) { }

  public index(): Observable<{}> {
    return this.http.get(environment.apiHost + '/dictionary', {});
  }
}
