import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError, shareReplay, take, tap} from 'rxjs/operators';
import {DictionaryService} from '../providers/dictionary.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../services/app-state';
import {setDictionary} from '../actions/dictionary.actions';
import {selectDictionary} from '../reducers/dictionary.reducer';

@Injectable({providedIn: 'root'})
export class DictionaryResolver implements Resolve<any> {
  private dictionary$: Observable<any>;
  constructor(private dictionaryService: DictionaryService, private store: Store<AppState>) {
    console.log('dict?');
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    console.log('setting dictionary');
    if (!this.dictionary$) {
      this.dictionary$ = this.dictionaryService.index().pipe(
        catchError(() => this.store.select(selectDictionary).pipe(take(1))),
        tap((dictionary) => this.store.dispatch(setDictionary({dictionary}))),
        take(1),
        shareReplay(1)
      );
    }
    return this.dictionary$;
  }
}
