import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../services/app-state';
import {mergeMap, switchMap, tap} from 'rxjs/operators';
import {isLoggingIn, loginSucceeded, logOut} from '../actions/auth.actions';
import {AuthService} from '../providers/auth.service';
import {NavController} from '@ionic/angular';
import {clearAuthUser, setAuthUser} from '../actions/auth-user.actions';
import {HOME_PAGE, LOGIN_PAGE} from '../../../constants';
import {createDashboardUrl} from '../../router/services/router.helper';
import {clearMessages} from '../../messages/actions/messages.actions';

@Injectable()
export class AuthEffects {

  constructor(private store: Store<AppState>,
              private authService: AuthService,
              private navCtrl: NavController,
              private readonly actions$: Actions) {
  }

  loginSucceeded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginSucceeded.type),
        switchMap(() => {
          return this.authService.getAuthUser().pipe(
            mergeMap((authUser) => [
              setAuthUser({authUser})
            ])
          );
        }),
        tap(() => {
          return this.navCtrl.navigateRoot(createDashboardUrl(HOME_PAGE));
        })
      );
    });

  logOut$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logOut.type),
        switchMap(() => [
          clearMessages(),
          clearAuthUser(),
        ]),
        tap(() => this.navCtrl.navigateRoot(LOGIN_PAGE))
      );
    });

}


