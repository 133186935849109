// Arvin: based on https://www.concretepage.com/angular-2/angular-candeactivate-guard-example

import {Observable, Subscription} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {hasRouteBlockingLoaders} from '../../loader/reducers/loader.reducer';
import {debounceTime, filter, take} from 'rxjs/operators';
import {LoadingController} from '@ionic/angular';
import {AppState} from '../../services/app-state';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<any>, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(private store: Store<AppState>, private loadingController: LoadingController) {}

  canDeactivate(component: any,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot) {

    return new Observable<boolean>((observer) => {
      // console.log('deactivated');
      this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
      }).then((loading) => {
        loading.present();
        this.subscriptions.push(this.store.select(hasRouteBlockingLoaders).pipe(
          filter((a) => !a),
          debounceTime(0),
          take(1)).subscribe((loaders) => {
          loading.dismiss();
          // console.log(loaders);
          observer.next(true);
          observer.complete();
        }));

      });
    });
  }

  ngOnDestroy() {
    this.unSubscribe();
  }

  private unSubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}
