import {createAction, props} from '@ngrx/store';
import {AuthUser} from '../models/auth-user.model';
import {LocationModel} from '../../locations/models/location.model';

export const clearAuthUser = createAction(
  '[Auth User] clear auth user'
);

export const setAuthUser = createAction(
  '[Auth User] set auth user',
  props<{ authUser: AuthUser }>(),
);

export const setAuthUserActiveLocationId = createAction(
  '[Auth User] set auth user active location id',
  props<{ locationId: number }>(),
);
