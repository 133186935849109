import {Injectable} from '@angular/core';
import {HttpClient} from '../../http/helpers/http-client.service';
import {environment} from '../../../../environments/environment';
import {of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {UpdateRequiredModel} from '../models/update-required.model';
import {AlertController, Platform} from '@ionic/angular';
import {WaTranslatePipe} from '../../dictionary/pipes/wa-translate.pipe';
import {AlertOptions} from '@capacitor/core';
import {VersionModel} from '../models/version.model';
import {Plugins} from '@capacitor/core';

const {Browser} = Plugins;

declare let cordova: any;

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(
    private http: HttpClient,
    public alertController: AlertController,
    public waTranslatePipe: WaTranslatePipe,
    private platform: Platform) {
  }

  validateVersion() {
    return this.http.get(environment.apiHost + '/update').pipe(
      map((res) => {
        if (!res || res.length === 0) {
          return [{
            updateRequired: false,
            hasUpdate: false
          }, res];
        }
        const versions = this.sortVersions(res.versions);
        return [this.updateRequired(versions, environment.version), res];
      }),
      tap(([update, res]) => {
        if (update.updateRequired || update.hasUpdate) {

          const buttons: any = [{
            text: this.waTranslatePipe.transform('UPDATE_APP_NOW'),
            cssClass: 'primary',
            handler: () => {
              if (this.platform.is('ios')) {
                window.open(res.latest.iosLink, '_blank');
                return false;
              } else if (this.platform.is('android')) {
                window.open(res.latest.androidLink, '_blank');
                return false;
              }
              location.reload();
            }
          }];

          if (!res.updateRequired) {
            buttons.push({
              text: this.waTranslatePipe.transform('UPDATE_APP_LATER'),
              role: 'cancel',
              cssClass: 'secondary'
            });
          }

          this.alertController.create({
            header: this.waTranslatePipe.transform('UPDATE_APP_TITLE'),
            backdropDismiss: false,
            message: this.waTranslatePipe.transform('UPDATE_APP_DESCRIPTION'),
            buttons
          }).then((alert) => alert.present());
        }
      })
    );
  }

  private sortVersions = (versions) => {
    return versions.map((e) => {
      e = {...e};
      e.splitted = e.version.split('.').map((e2) => {
        return +e2;
      });
      return e;
    }).sort((a, b) => {
      for (let i = 0; i < Math.max(a.splitted.length, b.splitted.length); i++) {
        if (a.splitted[i] - b.splitted[i] !== 0) {
          if (a.splitted[i] < b.splitted[i]) {
            return 1;
          } else {
            return -1;
          }
        }
      }
      return 0;
    }).map((e) => {
      return e;
    });
  };

  private updateRequired(versions: any, currentVersion: string): UpdateRequiredModel {
    const currVersion: number[] = currentVersion.split('.').map((e) => +e);
    return versions.reduce((res, version) => {
      const thisVersion = version.version.split('.').map((e) => +e);
      let foundBigger = false;
      for (let i = 0; i < Math.max(thisVersion.length, currentVersion.length); i++) {
        if (thisVersion[i] - currVersion[i] !== 0) {
          if (thisVersion[i] > currVersion[i]) {
            foundBigger = true;
            res.hasUpdate = true;
            if (version.required) {
              res.updateRequired = true;
            }
          }
          break;
        }
      }
      return res;
    }, {
      updateRequidred: false,
      hasUpdate: false,
    });
  }
}
