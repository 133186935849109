import {Injectable} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import {isDate, isEmpty, isNumber, isString} from 'lodash';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {catchError, flatMap, map, mergeMap, tap} from 'rxjs/operators';

@Injectable()
export class CacheService {

  /**
   * Default expiry in seconds  // Hour
   */
  defaultExpires = 3600;
  private readonly version: string;

  constructor(private localstorage: LocalStorageService) {
    this.version = environment.version;
  }

  /**
   * Cache or use result from observable
   *
   * If cache key does not exist or is expired, observable supplied in argument is returned and result cached
   */
  public observable<T>(key: string, observable: Observable<any>, expires: number = this.defaultExpires): Observable<any> {
    // First fetch the item from localstorage (even though it may not exist)
    return this.localstorage.getItem(key)
      // If the cached value has expired, nullify it, otherwise pass it through
      .pipe(
        map((val: CacheStorageRecord) => {
          if (val && val.version === this.version) {
            return (new Date(val.expires)).getTime() > Date.now() ? val : null;
          }
          return null;
        }),
        // At this point, if we encounter a null value, either it doesnt exist in the cache or it has expired.
        // If it doesnt exist, simply return the observable that has been passed in, caching its value as it passes through
        flatMap((val: CacheStorageRecord | null) => {
          if (!isEmpty(val)) {
            return of(val.value);
          } else {
            return observable.pipe(
              tap((val2) => this.value(key, val2, expires))
            );
          }
        }));
  }

  /**
   * Cache supplied value until expiry
   */
  value<T>(key: string, value: T, expires: number | string | Date = this.defaultExpires): Observable<T> {
    const expireDate: Date = this.sanitizeAndGenerateDateExpiry(expires);
    return this.localstorage.setItem(key, {
      expires: expireDate,
      value: value,
      version: this.version
    }).pipe(
      map(val => val.value),
      catchError((error): Observable<T> => {
        console.warn('Diskspace is full, clear diskspace');
        return of(value);
      }));
  }

  expire(key: string): Observable<void> {
    return this.localstorage.removeItem(key);
  }

  private sanitizeAndGenerateDateExpiry(expires: string | number | Date): Date {
    const expiryDate: Date = this.expiryToDate(expires);

    // Dont allow expiry dates in the past
    if (expiryDate.getTime() <= Date.now()) {
      return new Date(Date.now() + this.defaultExpires);
    }

    return expiryDate;
  }

  private expiryToDate(expires: number | string | Date): Date {
    if (isNumber(expires)) {
      return new Date(Date.now() + Math.abs(+expires) * 1000);
    }
    if (isString(expires)) {
      return new Date(expires);
    }
    if (isDate(expires) && expires instanceof Date) {
      return expires;
    }

    return new Date();
  }
}

/**
 * Cache storage record interface
 */
interface CacheStorageRecord {
  expires: Date;
  value: any;
  version: string;
}
