import {AuthUserStore} from '../models/auth-user-store.model';
import {clearAuthUser, setAuthUser, setAuthUserActiveLocationId} from '../actions/auth-user.actions';
import {AppState} from '../../services/app-state';
import {createSelector} from '@ngrx/store';
import {LocationModel} from '../../locations/models/location.model';
import {UserModel} from '../../user/models/user.model';
import {BaseProductModel} from '../../products/models/base-product.model';
import {selectIsLoggedIn} from './auth.reducer';

const initialAuthUser: AuthUserStore = {
  firstName: '',
  lastName: '',
  id: null,
  email: null,
  mobileNumber: null,
  locations: [],
  products: [],
  activeLocationId: null,
  contactPerson: null
};

export function AuthUserReducer(state = initialAuthUser, action: any = {type: ''}) {
  switch (action.type) {
    case clearAuthUser.type:
      return {...initialAuthUser};

    case setAuthUserActiveLocationId.type:
      return {
        ...state,
        activeLocationId: !!action.locationId ? +action.locationId : null
      };

    case setAuthUser.type:
      // Check if the current active Location still exists, otherwise, choose the first one
      let activeLocationId = null;
      if (!!state.activeLocationId && action.authUser.locations.some((location) => location.id === state.activeLocationId)) {
        activeLocationId = state.activeLocationId;
      } else if (action.authUser.locations.length > 0) {
        activeLocationId = action.authUser.locations[0].id;
      }

      return {
        ...state,
        ...action.authUser,
        activeLocationId
      };

    default:
      return state;
  }
}

export const selectAuthUser = (state: AppState): AuthUserStore => state.authUser;

export const selectActiveLocation = (state: AppState): LocationModel => state.authUser.locations
  .find((location) => location.id === state.authUser.activeLocationId);

export const selectLocations = (state: AppState): LocationModel[] => state.authUser.locations;

export const selectAuthUserBaseProducts = (state: AppState): BaseProductModel[] => state.authUser.products;

export const authUser = createSelector(
  selectAuthUser,
  (selectedAuthUser: AuthUserStore) => selectedAuthUser
);
export const authUserProducts = createSelector(
  selectActiveLocation,
  (selectedLocation: LocationModel) => selectedLocation ? selectedLocation.products : []
);
export const locations = createSelector(
  selectLocations,
  (locationModels: LocationModel[]) => locationModels
);
export const needsSafeAreaTop = createSelector(
  selectLocations,
  selectIsLoggedIn,
  (locationModels: LocationModel[], isLoggedIn) => locationModels.length === 0 || !isLoggedIn
);

export const activeLocation = createSelector(
  selectActiveLocation,
  (locationModel: LocationModel) => locationModel
);

export const activeContactPerson = createSelector(
  selectActiveLocation,
  (locationModel: LocationModel) => locationModel ? locationModel.contactperson : null
);
