import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {AppState} from '../../services/app-state';
import {catchError, concatMap, map, mergeMap, take} from 'rxjs/operators';
import {NavController} from '@ionic/angular';
import {MessageService} from '../services/message.service';
import {
  addMessage,
  loadingMessagesForLocation,
  loadMessages,
  loadMessagesByLocationId,
  MessageActionsUnion,
  messagesLoadedForLocation, messagesLoadingForLocationFailed
} from '../actions/messages.actions';
import {selectLocations} from '../../auth/reducers/auth-user.reducer';
import {combineLatest, concat, Observable, of, throwError} from 'rxjs';
import {selectMessageLocations, selectMessages} from '../reducers/messages.reducer';
import {ErrorService} from '../../errors/providers/error.service';

@Injectable()
export class MessagesEffects {

  constructor(private store: Store<AppState>,
              private messageService: MessageService,
              private navCtrl: NavController,
              private errorService: ErrorService,
              private readonly actions$: Actions<MessageActionsUnion>) {
  }

  loadMessages$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loadMessages.type),
        concatMap(() => {
          return combineLatest([
            this.store.select(selectLocations).pipe(take(1)),
            this.store.select(selectMessageLocations).pipe(take(1)),
            this.store.select(selectMessages).pipe(map((messages) => messages.length === 0), take(1)),
          ]).pipe(
            mergeMap(([locations, messageLocations, isFirstLoad]) => {
              let dateFrom = null;

              let foundMessageLocation = messageLocations.find((messageLocation) => messageLocation.locationId === null);

              if (foundMessageLocation) {
                dateFrom = foundMessageLocation.dateFrom;
              }

              const baseActions: Observable<Action>[] = [
                of(loadingMessagesForLocation()),
                this.messageService.index(null, dateFrom).pipe(
                  mergeMap((data) => {
                    return data.map((message) => addMessage(message, isFirstLoad));
                  }),
                  catchError(() => {
                    this.errorService.showDefaultError();
                    return of(messagesLoadingForLocationFailed());
                  })
                ),
                of(messagesLoadedForLocation())
              ];

              let locationBased: Observable<Action>[][] = [];

              // Get message by location (and without location)
              if (locations.length > 0) {
                locationBased = locations.map((location) => {
                  dateFrom = null;

                  foundMessageLocation = messageLocations.find((messageLocation) => messageLocation.locationId === location.id);

                  if (foundMessageLocation) {
                    dateFrom = foundMessageLocation.dateFrom;
                  }

                  if (!dateFrom) {
                    dateFrom = '1970-01-01 00:00:00';
                  }

                  return [
                    of(loadingMessagesForLocation(location)),
                    this.messageService.index(location.id, dateFrom).pipe(
                      mergeMap((data) => {
                        return data.map((message) => addMessage(message, isFirstLoad));
                      }),
                      catchError(() => {
                        this.errorService.showDefaultError();
                        return of(messagesLoadingForLocationFailed());
                      })
                    ),
                    of(messagesLoadedForLocation(location))
                  ];
                });
              }
              return concat<Action[]>(...[].concat.apply(baseActions, locationBased));
            })
          );
        }),
      );
    });

  loadMessagesByLocationId$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loadMessagesByLocationId.type),
        concatMap((action) => {
          return combineLatest([
            this.store.select(selectMessageLocations).pipe(take(1))
          ]).pipe(
            mergeMap(([messageLocations]) => {
              let dateFrom = null;
              console.log(action);
              const foundMessageLocation = messageLocations.find((messageLocation) => messageLocation.locationId === action.locationId);
              console.log(messageLocations, foundMessageLocation);

              if (foundMessageLocation) {
                dateFrom = foundMessageLocation.dateFrom;
              }

              if (!dateFrom) {
                dateFrom = '1970-01-01 00:00:00';
              }

              const baseActions: Observable<Action>[] = [
                of(loadingMessagesForLocation()),
                this.messageService.index(action.locationId, dateFrom).pipe(
                  mergeMap((data) => {
                    return data.map((message) => addMessage(message));
                  }),
                  catchError(() => {
                    this.errorService.showDefaultError();
                    return of(messagesLoadingForLocationFailed());
                  })
                ),
                of(messagesLoadedForLocation())
              ];

              return concat<Action[]>(...[].concat.apply(baseActions));
            })
          );
        }),
      );
    });
}


